export function getScreenWidth(inputScreenWidth: number) {
    const screenWidth: number = inputScreenWidth > 0 ? inputScreenWidth : window.innerWidth;
    return screenWidth;
}
export function getVisiblityPercent(width: number, screenWidth: number) {
    const visiblityPercent: number = screenWidth < width ? 100 : (100 * width) / screenWidth;
    return visiblityPercent;
}
export function getHeight(margins: number, visiblityPercent: number, aspect_ratio: number, screenWidth: number) {
    let height;
    let percent = ((screenWidth - margins) * visiblityPercent) / 100;
    height = percent / aspect_ratio;
    return height;
}
export function findImageDimensions(margins: number, visibilityPercent: number, aspect_ratio: number = -1) {
    const width = ((100 - margins) * visibilityPercent) / 100;
    if (aspect_ratio > 0) {
      const height = width / aspect_ratio;
      return { width: width, height: height };
    } else return { width: width };
}
export const defultDesktopScreenWidth: number = 1260;
export const isSSR: boolean = typeof window === "undefined";
//We cannot set screen width based on window.screen.width because we do not know device
//screen width during SSR.
//This means we will have to adjust swiper element heights to always work with the value 360
export const mobileScreenWidth: number = isSSR ? 412 : window.screen.width; 
